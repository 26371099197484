// eslint-disable-next-line import/prefer-default-export
import { IDR } from '@/libs/currency'
import { DAY_MONTH_YEAR, LABEL_MONTH_YEAR } from '@/libs/filterDate'

export const tableActive = [
  {
    key: 'id',
    label: 'ID',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'partner_name',
    label: 'Nama partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'invoice_code',
    label: 'No Invoice',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'invoice_paid',
    label: 'Invoice Terbayar',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'nominal',
    label: 'Nominal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
      textAlign: 'center',
    },
    tdClass: 'text-black',
    formatter: value => IDR(value),
  },
  {
    key: 'additional_cost',
    label: 'Biaya Lainnya',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
      textAlign: 'center',
    },
    tdClass: 'text-black',
    formatter: value => IDR(value),
  },
  {
    key: 'published',
    label: 'Published',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      textAlign: 'center',
      padding: '12px 7px',
      width: '80px',
    },
    tdClass: 'text-black text-center',
  },
  {
    key: 'paid',
    label: 'Paid',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      textAlign: 'center',
      padding: '12px 7px',
      width: '80px',
    },
    tdClass: 'text-black text-center',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      textAlign: 'center',
      padding: '12px 7px',
    },
    tdClass: 'text-black text-center',
  },
]

export const tableHistory = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'partner_name',
    label: 'Nama partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'xendit_external_id',
    label: 'No Invoice',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'payment_date',
    label: 'Tanggal Pembayaran',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black',
    formatter: value => DAY_MONTH_YEAR(value),
  },
  {
    key: 'nominal',
    label: 'Nominal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
      textAlign: 'center',
    },
    tdClass: 'text-black',
    formatter: value => IDR(value),
  },
  {
    key: 'paid',
    label: 'Paid',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      textAlign: 'center',
      padding: '12px 7px',
      width: '80px',
    },
    tdClass: 'text-black text-center',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      textAlign: 'center',
      padding: '12px 7px',
    },
    tdClass: 'text-black text-center',
  },
]

export const tableTalent = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'talent_name',
    label: 'Nama Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'admin_fee',
    label: 'Biaya Admin',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'discount',
    label: 'Diskon',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      width: '50px',
      padding: '12px 7px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'talent_salary',
    label: 'Gaji Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
      width: '150px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'additional_cost',
    label: 'Biaya Lainnya',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
      width: '150px',
    },
    tdClass: 'text-black',
  },
  {
    key: 'total',
    label: 'Total',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
      width: '120px',
    },
    tdClass: 'text-black',
    formatter: (value, key, item) => IDR(item.admin_fee - (item.admin_fee * ((item.discount || 0) / 100)) + item.talent_salary + item.additional_cost),
  },
  {
    key: 'action',
    label: '',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black',
  },
]

export const tableDisbursement = [
  {
    key: 'id',
    label: 'ID',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
  },
  {
    key: 'talent_name',
    label: 'Nama Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
  },
  {
    key: 'admin_fee',
    label: 'Biaya Admin',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
    formatter: value => IDR(value),
  },
  {
    key: 'discount',
    label: 'Diskon',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
  },
  {
    key: 'talent_salary',
    label: 'Gaji Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
    formatter: value => IDR(value),
  },
  {
    key: 'additional_cost',
    label: 'Biaya Lainnya',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
    formatter: value => IDR(value),
  },
  {
    key: 'sharing_fee',
    label: 'Sharing Fee',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
    formatter: value => (value <= 100 ? `${value}%` : IDR(value)),
  },
  {
    key: 'app_fee',
    label: 'Biaya aplikasi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
    formatter: () => IDR(5500),
  },
  {
    key: 'total',
    label: 'Total',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
    formatter: value => IDR(value),
  },
]

export const tableDetailHistory = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
  },
  {
    key: 'talent_name',
    label: 'Nama Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
  },
  {
    key: 'admin_fee',
    label: 'Biaya Admin',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
    formatter: value => IDR(value),
  },
  {
    key: 'talent_salary',
    label: 'Gaji Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
    formatter: value => IDR(value),
  },
  {
    key: 'additional_cost',
    label: 'Biaya Lainnya',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
    formatter: value => IDR(value),
  },
  {
    key: 'total',
    label: 'Total',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
    formatter: value => IDR(value),
  },
  {
    key: 'status',
    label: 'Status Disbursement',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black',
  },
]

export const tableMonitor = [
  {
    key: 'id',
    label: 'ID',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'talent_name',
    label: 'Nama Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'admin_fee',
    label: 'Biaya Admin',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    formatter: value => IDR(value),
  },
  {
    key: 'talent_salary',
    label: 'Gaji Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    formatter: value => IDR(value),
  },
  {
    key: 'additional_cost',
    label: 'Biaya Lainnya',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      padding: '12px 7px',
    },
    tdClass: 'text-black py-1',
    formatter: value => IDR(value),
  },
  {
    key: 'updated_at',
    label: 'tgl pembayaran',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    formatter: value => DAY_MONTH_YEAR(value),
  },
  {
    key: 'disbursement_status',
    label: 'disbursement',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'action',
    label: 'aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
]

export const tableFailedDisburse = [
  {
    key: 'checkbox',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'talent_name',
    label: 'Nama Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'partner_name',
    label: 'Nama Partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'invoice_date',
    label: 'Periode',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    formatter: value => LABEL_MONTH_YEAR(value),
  },
  {
    key: 'nominal',
    label: 'Nominal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      textAlign: 'center',
    },
    tdClass: 'text-black',
    formatter: value => IDR(value),
  },
  {
    key: 'status',
    label: 'Disbursement',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    formatter: value => DAY_MONTH_YEAR(value),
  },
]

export const filterOptions = [
  { text: 'Draft', value: 'draft' },
  { text: 'Published', value: 'published' },
  { text: 'Paid', value: 'paid' },
]

export const isEarlyPaymentOptions = [
  {
    label: 'Pembayaran Awal',
    value: true,
  },
  {
    label: 'Pembayaran Akhir',
    value: false,
  },
]
